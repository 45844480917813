import { Col, Row, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';
import IconEye from 'src/assets/icons/common/icon-eye.svg';
import DefaultImage from 'src/assets/images/default-image.png';
import { TOKEN_FIAT, VAULT_TYPE } from 'src/constants';
import { FULL_DATETIME_FORMAT_2 } from 'src/constants/formatters';
import { PATHS } from 'src/constants/paths';
import { renderReveneuStages, renderStatusRevenue } from '.';
import './styles.scss';

const RevenueItem: React.FC<{ revenueItem: any; isDetail?: boolean }> = ({ revenueItem, isDetail = false }) => {
  const {
    assignedBD,
    fractor,
    acceptedCurrencySymbol,
    fiatSymbol,
    eventPhotoUrl,
    iaoEventId,
    iaoEventName,
    participants,
    participatedAmount,
    participatedByFiatAmount,
    participationEndTime,
    participationStartTime,
    progress,
    revenue,
    soldAmount,
    stage,
    tokenSymbol,
    vaultType,
    vaultUnlockThreshold,
  } = revenueItem || {};
  return (
    <div className='revenue-item'>
      <Row gutter={15}>
        <Col span={8}>
          <div className='d-flex iao-event items-center mb-16'>
            <Link className='img-iao-event' to={PATHS.iaoEventDetailID(iaoEventId)} target='_blank' rel='noreferrer'>
              <img width={64} src={eventPhotoUrl || DefaultImage} alt='' />
            </Link>
            <div>
              <Link to={PATHS.iaoEventDetailID(iaoEventId)} target='_blank' rel='noreferrer'>
                <Tooltip title={iaoEventName?.['en']} className='mb-8 iao-title one-line'>
                  {iaoEventName?.['en']}
                </Tooltip>
              </Link>
              <p className='mb-8 vault-type'>
                {iaoEventId} <span>{vaultType === VAULT_TYPE.VAULT ? '(Vault)' : '(Non-vault)'}</span>
              </p>
            </div>
          </div>
          <div className='mb-8'>{renderReveneuStages(stage)}</div>
          <div>
            <label className='participation-period mb-8'>Participation period</label>
            <div className='mb-8 text-12'>
              <span>Start: </span>
              <span>{moment(participationStartTime).format(FULL_DATETIME_FORMAT_2)}</span>
            </div>
            <div className='mb-8 text-12'>
              <span>End: </span>
              <span>{moment(participationEndTime).format(FULL_DATETIME_FORMAT_2)}</span>
            </div>
          </div>
        </Col>
        <Col span={isDetail ? 6 : 5}>
          <div className='label-info'>
            <label className='label-title mb-8'>Sold</label>
            <div>
              <Tooltip title={`${soldAmount} ${tokenSymbol}`}>
                <NumericFormat
                  className='font-bold'
                  decimalScale={2}
                  displayType='text'
                  value={soldAmount}
                  thousandSeparator=','
                  suffix={` ${tokenSymbol}`}
                />
              </Tooltip>
            </div>
          </div>
          <div className='label-info'>
            <label className='label-title mb-8'>Participants</label>
            {participants}
          </div>
          {isDetail && (
            <div>
              <label className='label-title font-bold mb-8'>Fractor</label>
              <Link
                className='fractor'
                to={PATHS.accountsFractorDetailId(fractor?.fractorId)}
                target='_blank'
                rel='noreferrer'
              >
                {`${fractor?.fractorId || ''} - ${fractor?.fullname || ''}`}
              </Link>
            </div>
          )}
        </Col>
        <Col span={isDetail ? 5 : 4}>
          <div className='label-info'>
            <label className='label-title mb-8'>
              {vaultType === VAULT_TYPE.NON_VAULT ? 'Participated (Crypto)' : 'Participated'}
            </label>
            <div>
              <Tooltip title={`${participatedAmount} ${acceptedCurrencySymbol}`}>
                <NumericFormat
                  className='font-bold'
                  decimalScale={2}
                  displayType='text'
                  value={participatedAmount}
                  thousandSeparator=','
                  suffix={` ${acceptedCurrencySymbol}`}
                />
              </Tooltip>
            </div>
          </div>
          <div className='label-info'>
            <label className='label-title mb-8'>Progress</label>
            <NumericFormat decimalScale={2} displayType='text' value={progress} thousandSeparator=',' suffix=' %' />
          </div>
          {isDetail && (
            <div>
              <label className='label-title font-bold mb-8'>BD</label>
              {assignedBD?.adminId ? (
                <Link
                  className='assign-bd'
                  to={PATHS.adminAccountDetailId(assignedBD?.adminId)}
                  target='_blank'
                  rel='noreferrer'
                >
                  {assignedBD?.adminId || ''} - {assignedBD?.fullname || ''}
                </Link>
              ) : (
                <span className='assign-bd'>--</span>
              )}
            </div>
          )}
        </Col>
        <Col span={5}>
          {vaultType === VAULT_TYPE.VAULT && (
            <div className='label-info'>
              <label className='label-title mb-8'>Unlock threshold</label>
              <div>{vaultUnlockThreshold} %</div>
            </div>
          )}
          {!isDetail && vaultType === VAULT_TYPE.NON_VAULT && (
            <div className='label-info'>
              <label className='label-title mb-8'>Participated (Fiat)</label>
              <div>
                <Tooltip title={`${participatedByFiatAmount || 0} ${fiatSymbol}`}>
                  <NumericFormat
                    className='font-bold'
                    decimalScale={2}
                    displayType='text'
                    value={participatedByFiatAmount || 0}
                    thousandSeparator=','
                    suffix={` ${fiatSymbol}`}
                  />
                </Tooltip>
              </div>
            </div>
          )}
          {vaultType === VAULT_TYPE.NON_VAULT && isDetail && (
            <div className='label-info'>
              <label className='label-title mb-8'>Participated (Fiat)</label>
              <div>
                <Tooltip title={`${participatedByFiatAmount} ${TOKEN_FIAT.SGD}`}>
                  <NumericFormat
                    className='font-bold'
                    decimalScale={2}
                    displayType='text'
                    value={participatedByFiatAmount}
                    thousandSeparator=','
                    suffix={` ${TOKEN_FIAT.SGD}`}
                  />
                </Tooltip>
              </div>
            </div>
          )}
          {!isDetail && (
            <>
              <div>
                <label className='label-title mb-8 revenue-status'>Revenue status</label>
                <div>{renderStatusRevenue(revenue?.status)}</div>
              </div>
            </>
          )}
        </Col>
        {!isDetail && (
          <Col span={2}>
            <Link to={PATHS.iaoRevenueById(iaoEventId)}>
              <img className='cursor-pointer' src={IconEye} alt='' />
            </Link>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RevenueItem;
